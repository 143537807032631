/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import router from '../router'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { loadFonts } from './webfontloader'

// Types
import type { App } from 'vue'

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

export function registerPlugins(app: App) {
  loadFonts()
  app.use(vuetify).use(pinia).use(router)
}
