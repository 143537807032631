import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'

// load env from root
export default createVuetify({
  ssr: true,
  theme: {
    themes: {
      light: {
        colors: {
          primary: '#59C4AE',
          secondary: '#4B8DF1',
          error: '#EB3C2B',
        },
      },
    },
    cspNonce: import.meta.env.VITE_CSP_NONCE
  },
})
