import './assets/main.scss'
import { createApp } from 'vue'
import App from './App.vue'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
import { registerPlugins } from '@/plugins'
import Popper from 'vue3-popper'
import './assets/css/popper-theme.css'
import vue3GoogleLogin from 'vue3-google-login'
import 'dayjs/locale/th'
import dayjs from 'dayjs'

registerPlugins(app)
app.component('Popper', Popper)

// vue3-google-login
app.use(vue3GoogleLogin, {
  clientId: import.meta.env.VITE_GOOGLE_CLIENT_ID
})

// sentry
if (import.meta.env.VITE_SENTRY_DSN !== undefined) {
  const BACKEND_HOSTNAME = new URL(import.meta.env.VITE_BACKEND_BASE_URL).hostname
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['*'],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

// config dayjs locale
dayjs.locale('th')

app.mount('#app')
