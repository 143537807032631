import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/SessionLayout.vue'),
      children: [
        {
          path: '',
          name: 'new_chat',
          component: () => import('@/views/chat_view.vue'),
          meta: {
            title: 'GenAI | Chat',
            requiresAuth: true
          }
        },
        {
          path: 'c/:id',
          name: 'chat_by_id',
          component: () => import('@/views/chat_view.vue'),
          meta: {
            title: 'GenAI | Chat',
            requiresAuth: true
          },
          beforeEnter: async (to, from, next) => {
            const sessionStore = useSessionStore()
            try {
              // extract session id
              let session_id = ''
              if (Array.isArray(to.params.id)) {
                session_id = to.params.id[0]
              } else {
                session_id = to.params.id
              }

              // Check if chat_by_id exists
              const chatExists = await sessionStore.checkChatExists(session_id)
              if (chatExists) {
                next()
              } else {
                next({ name: 'new_chat' })
              }
            } catch (error) {
              next({ name: 'error', params: { message: 'Error checking chat' } })
            }
          }
        }
      ]
    },
    {
      path: '/admin',
      component: () => import('@/layouts/AdminSessionLayout.vue'),
      children: [
        {
          path: '',
          name: 'admin_dashboard',
          component: () => import('@/views/admin_dashboard.vue'),
          meta: {
            title: 'GenAI | Dashboard',
            requiresAuth: true
          }
        },
        {
          path: 'test_chat',
          name: 'test_chat_box',
          component: () => import('@/views/test_chat_box.vue'),
          meta: {
            title: 'GenAI | Test Chat',
            requiresAuth: true
          }
        },
        {
          path: 'customers',
          children: [
            {
              path: '',
              name: 'manage_customers',
              component: () => import('@/views/manange_customers.vue'),
              meta: {
                title: 'GenAI | Customers Management',
                requiresAuth: true
              }
            },
            {
              path: 'create_customer',
              name: 'create_customer',
              component: () => import('@/views/create_customer.vue'),
              meta: {
                title: 'GenAI | Create Customer',
                requiresAuth: true
              }
            },
            {
              path: 'edit_customer/:customer_id',
              name: 'edit_customer',
              component: () => import('@/views/edit_customer.vue'),
              meta: {
                title: 'GenAI | Edit Customer',
                requiresAuth: true
              }
            },
            {
              path: 'detail/:customer_id',
              name: 'view_customer_detail',
              component: () => import('@/views/view_customer_detail.vue'),
              meta: {
                title: 'GenAI | View Session',
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'chat_session',
          children: [
            {
              path: '',
              name: 'manage_chat_session',
              component: () => import('@/views/manage_chat_session.vue'),
              meta: {
                title: 'GenAI | Manage Chat Session',
                requiresAuth: true
              }
            },
            {
              path: 'view_session/:session_id',
              name: 'view_session',
              component: () => import('@/views/view_session.vue'),
              meta: {
                title: 'GenAI | View Session',
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'manage_users',
          children: [
            {
              path: '',
              name: 'manage_users',
              component: () => import('@/views/manange_users.vue'),
              meta: {
                title: 'GenAI | Manage Users',
                requiresAuth: true
              }
            },
            {
              path: 'create_user',
              name: 'create_user',
              component: () => import('@/views/create_user.vue'),
              meta: {
                title: 'GenAI | Create Users',
                requiresAuth: true
              }
            },
            {
              path: 'edit_user/:user_id',
              name: 'edit_user',
              component: () => import('@/views/edit_user.vue'),
              meta: {
                title: 'GenAI | Edit Users',
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'usage',
          children: [
            {
              path: '',
              name: 'usage',
              component: () => import('@/views/usage.vue'),
              meta: {
                title: 'GenAI | Usage',
                requiresAuth: true
              }
            }
          ]
        },
        {
          path: 'automated-test',
          children: [
            {
              path: '',
              name: 'manage-test-cases',
              component: () => import('@/views/automated_test/manage_test_case.vue'),
              meta: {
                title: 'GenAI | Manage test case',
                requiresAuth: true
              }
            },
            {
              path: 'new',
              name: 'new-test-case',
              component: () => import('@/views/automated_test/new_test_case.vue'),
              meta: {
                title: 'GenAI | New test case',
                requiresAuth: true
              }
            },
            {
              path: 'test-case/:test_case_id',
              children: [
                {
                  path: '',
                  name: 'manage-test-result',
                  component: () => import('@/views/automated_test/manage_test_result.vue'),
                  meta: {
                    title: 'GenAI | Manage test result',
                    requiresAuth: true
                  }
                },
                {
                  path: 'result/:test_result_id',
                  name: 'view-test-result',
                  component: () => import('@/views/automated_test/view_test_result.vue'),
                  meta: {
                    title: 'GenAI | View Test result',
                    requiresAuth: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'configurations',
          children: [
            {
              path: '',
              name: 'general',
              component: () => import('@/views/general_configurations.vue'),
              meta: {
                title: 'GenAI | Configurations General',
                requiresAuth: true
              }
            },
            {
              path: 'intents',
              children: [
                {
                  path: '',
                  name: 'intents',
                  component: () => import('@/views/intents_management.vue'),
                  meta: {
                    title: 'GenAI | Configurations Intents',
                    requiresAuth: true
                  }
                },
                {
                  path: 'create_intent',
                  name: 'create_intent',
                  component: () => import('@/views/create_intent.vue'),
                  meta: {
                    title: 'GenAI | Create Intent',
                    requiresAuth: true
                  }
                },
                {
                  path: 'edit_intent/:intent_id',
                  name: 'edit_intent',
                  component: () => import('@/views/edit_intent.vue'),
                  meta: {
                    title: 'GenAI | Edit Intent',
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: 'generator',
              children: [
                {
                  path: '',
                  name: 'generator',
                  component: () => import('@/views/generator_management.vue'),
                  meta: {
                    title: 'GenAI | Configurations Generator',
                    requiresAuth: true
                  }
                },
                {
                  path: 'create_generator',
                  name: 'create_generator',
                  component: () => import('@/views/create_generator.vue'),
                  meta: {
                    title: 'GenAI | Create Generator',
                    requiresAuth: true
                  }
                },
                {
                  path: 'edit_generator/:generator_id',
                  name: 'edit_generator',
                  component: () => import('@/views/edit_generator.vue'),
                  meta: {
                    title: 'GenAI | Edit Generator',
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: 'datas',
              children: [
                {
                  path: '',
                  name: 'datas',
                  component: () => import('@/views/manange_datas.vue'),
                  meta: {
                    title: 'GenAI | Configurations Datas',
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: 'integrations',
              children: [
                {
                  path: '',
                  name: 'integrations',
                  component: () => import('@/views/manage_integration.vue'),
                  meta: {
                    title: 'GenAI | Configurations Integrations',
                    requiresAuth: true
                  }
                },
                {
                  path: 'line',
                  children: [
                    {
                      path: 'create',
                      name: 'create_line_integration',
                      component: () => import('@/views/create_line_integration.vue'),
                      meta: {
                        title: 'GenAI | Create line integration',
                        requiresAuth: true
                      }
                    },
                    {
                      path: 'edit/:lineIntegrationId',
                      name: 'edit_line_integration',
                      component: () => import('@/views/edit_line_integration.vue'),
                      meta: {
                        title: 'GenAI | Edit line integration',
                        requiresAuth: true
                      }
                    }
                  ]
                },
                {
                  path: 'messenger',
                  children: [
                    {
                      path: 'create',
                      name: 'create_messenger_integration',
                      component: () => import('@/views/create_messenger_integration.vue'),
                      meta: {
                        title: 'GenAI | Create Messenger integration',
                        requiresAuth: true
                      }
                    },
                    {
                      path: 'edit/:messengerIntegrationId',
                      name: 'edit_messenger_integration',
                      component: () => import('@/views/edit_messenger_integration.vue'),
                      meta: {
                        title: 'GenAI | Edit messenger integration',
                        requiresAuth: true
                      }
                    }
                  ]
                }
              ]
            },
            {
              path: 'generative_ai',
              children: [
                {
                  path: '',
                  name: 'generative_ai',
                  component: () => import('@/views/generative_ais_management.vue'),
                  meta: {
                    title: 'GenAI | Configurations Generative AIs',
                    requiresAuth: true
                  }
                }
              ]
            },
            {
              path: 'chat-feature',
              children: [
                {
                  path: '',
                  name: 'chat-feature',
                  component: () => import('@/views/chat_feature.vue'),
                  meta: {
                    title: 'GenAI | Configurations chat features',
                    requiresAuth: true
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'cost-history',
          children: [
            {
              path: '',
              name: 'cost-history',
              component: () => import('@/views/cost/history.vue'),
              meta: {
                title: 'GenAI | Cost history',
                requiresAuth: true
              }
            },
            {
              path: 'export',
              name: 'export-cost-history',
              component: () => import('@/views/cost/export.vue'),
              meta: {
                title: 'GenAI | Export cost history',
                requiresAuth: true
              }
            }
          ]
        }
      ]
    },
    {
      path: '/auth',
      component: () => import('@/layouts/AuthLayout.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          component: () => import('@/views/login.vue'),
          meta: {
            title: 'GenAI | Login'
          }
        },
        {
          path: 'recover',
          name: 'recovery-account',
          component: () => import('@/views/recover_account.vue'),
          meta: {
            title: 'GenAI | Recovery account'
          }
        },
        {
          path: 'admin_login',
          name: 'admin_login',
          component: () => import('@/views/admin_login.vue'),
          meta: {
            title: 'GenAI | Login Admin'
          }
        }
      ]
    },
    {
      path: '/rtarf',
      children: [
        {
          path: 'auth',
          component: () => import('@/layouts/RtarfAuthLayout.vue'),
          children: [
            {
              path: 'login',
              name: 'rtarf-login',
              component: () => import('@/views/rtarf/auth/login.vue'),
              meta: {
                title: 'GenAI | Login'
              }
            }
          ]
        },
        {
          path: '',
          component: () => import('@/layouts/RtarfSessionLayout.vue'),
          children: [
            {
              path: '',
              name: 'rtarf-new_chat',
              component: () => import('@/views/rtarf/chat_view.vue'),
              meta: {
                title: 'GenAI | Chat',
                requiresAuth: true
              },
              beforeEnter: async (to, from, next) => {
                const sessionStore = useSessionStore()
                const { session_id: sessionSelected } = storeToRefs(sessionStore)
                sessionSelected.value = ''
                next()
              }
            },
            {
              path: 'c/:session_id',
              name: 'rtarf-chat_by_id',
              component: () => import('@/views/rtarf/chat_view.vue'),
              meta: {
                title: 'GenAI | Chat',
                requiresAuth: true
              },
              beforeEnter: async (to, from, next) => {
                const sessionStore = useSessionStore()
                const { session_id: sessionSelected } = storeToRefs(sessionStore)

                try {
                  // extract session id
                  const session_id = (to?.params?.session_id || '') as string

                  // Check if chat_by_id exists
                  const chatExists = await sessionStore.checkChatExists(session_id)
                  sessionSelected.value = session_id

                  if (chatExists) {
                    next()
                  } else {
                    next({ name: 'rtarf-new_chat' })
                  }
                } catch (error) {
                  next({ name: 'error', params: { message: 'Error checking chat' } })
                }
              }
            }
          ]
        }
      ]
    }
  ]
})

import { useAuthStore } from '@/stores/auth'

import { useSessionStore } from '@/stores/session'
import { storeToRefs } from 'pinia'

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const { role, accessToken } = storeToRefs(authStore)

  const pageTitle = to.meta.title || 'GenAI'

  const preFixRtarf = to.path.startsWith('/rtarf') ? 'rtarf-' : ''

  document.title = String(pageTitle)
  // Check if the route requires authentication]
  if (to.meta.requiresAuth) {
    // If token exists, set it in the route's metadata
    if (accessToken.value) {
      to.meta.token = accessToken.value

      // If the role is not 'admin' and trying to access the admin page, redirect to the login page
      if (
        role.value !== 'looloo_admin' &&
        role.value !== 'customer_manager' &&
        to.path.includes('/admin')
      ) {
        return {
          name: `${preFixRtarf}login`
        }
      }
    } else {
      // Redirect to the login page if token doesn't exist
      return {
        name: `${preFixRtarf}login`
      }
    }
  }
})

export default router
