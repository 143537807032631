import { useAuthStore } from '@/stores/auth'
import axios, { AxiosError } from 'axios'
import { storeToRefs } from 'pinia'
import { useRouter } from 'vue-router'

type UNAUTHORIZED_RESPONSE = {
  detail: string
}

const INTERCEPTOR_DISABLED_ROUTES = [
  // login api
  'account/login',

  // logout api
  'account/logout',

  // google login
  'account/google/login/callback',

  // refresh token api
  'account/refresh-token'
]

export const useAxios = () => {
  const authStore = useAuthStore()
  const { accessToken, isRefreshingToken } = storeToRefs(authStore)

  const instance = axios.create({
    baseURL: import.meta.env.VITE_APP_API_URL
  })

  const requestInterceptor = instance.interceptors.request.use(
    function (config) {
      // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
      if (config.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(config.url)) {
        return config
      }

      // Check is token defined in localstorage or not
      if (accessToken.value) {
        // Bind Authorization header if it is not already set
        if (config.headers['Authorization'] === undefined) {
          config.headers['Authorization'] = `Bearer ${accessToken.value}`
        }
      }
      return config
    },
    function (error) {
      // Do nothing
      return Promise.reject(error)
    }
  )

  const responseInterceptor = instance.interceptors.response.use(
    function (response) {
      // No action required for status 200

      return response
    },
    async function (error) {
      // check is request's endpoint in INTERCEPTOR_DISABLED_ROUTES or not
      if (error.url !== undefined && INTERCEPTOR_DISABLED_ROUTES.includes(error.url)) {
        return Promise.reject(error)
      }

      // Handle 401 and specific 403
      if (
        (error as AxiosError).response !== undefined &&
        (error as AxiosError).response?.data !== undefined
      ) {
        const errorResponseData = (error as AxiosError).response?.data
        let error_message = ''
        if (errorResponseData instanceof Blob) {
          try {
            error_message = JSON.parse(await errorResponseData.text())?.detail
          } catch (error) {
            return Promise.reject(error)
          }
        } else if (typeof errorResponseData === 'string') {
          error_message = JSON.parse(errorResponseData).detail
        } else {
          error_message = (errorResponseData as UNAUTHORIZED_RESPONSE).detail
        }

        if (
          ((error as AxiosError).response?.status === 401 ||
            (error as AxiosError).response?.status === 403) &&
          (error_message === 'Invalid token.' || error_message === 'User inactive or deleted.')
        ) {
          // Invalid token case
          await authStore.logout()
          window.location.reload()
        } else if (
          (error as AxiosError).response?.status === 403 &&
          error_message === 'Token expired.'
        ) {
          const originalRequest = error.config

          if (isRefreshingToken.value === true) {
            // wait token to be refreshed (pooling)
            while (isRefreshingToken.value === true) {
              // sleep
              await new Promise((resolve) => setTimeout(resolve, 100))
            }

            if (accessToken.value) {
              // retry this request after token refreshed

              originalRequest.headers['Authorization'] = `Bearer ${accessToken.value}`
              return instance(originalRequest)
            } else {
              // in case refresh token fail, return reject
              return Promise.reject(error)
            }
          } else {
            // Access token expired case
            isRefreshingToken.value = true

            // call api to refresh token
            try {
              await authStore.refresh()
              // Retry the original request with the new token
              originalRequest.headers['Authorization'] = `Bearer ${accessToken.value}`
              return instance(originalRequest)
            } catch (refreshError) {
              // If refresh fails, logout the user
              await authStore.logout()
              window.location.reload()
            } finally {
              isRefreshingToken.value = false
            }
          }
        }
      }

      return Promise.reject(error)
    }
  )

  return { instance, requestInterceptor, responseInterceptor }
}
